import BaseService from '@/services/BaseService'
import { WbEnvironments } from '@interco/inter-webview-bridge'

declare global {
  interface Window {
    dataLayer: Content[]
  }
}

interface Content {
  ref_figma?: string
  content_id?: string
  screen?: string
  content_action?: string
  seguro_fgts_premiavel?: string
  price?: number
  currency?: string
  autorizacao?: boolean
  product?: string
  origem?: string
  event: Events
  fluxo: 'ALTERNATIVO' | 'NORMAL'
  value?: number
  adjust_event_token?: string
  ref_type?: string
  action_id?: string
  utmCampaign?: string
}

export default {
  add: (value: Content) => {
    window?.dataLayer.push({ ...value, origem: 'novo_simulador_v2' })
    if (BaseService.getEnvironment() !== WbEnvironments.BROWSER) {
      BaseService.sendAnalyticsEvent({
        name: value.event,
        params: {
          ...value,
          value: String(value?.value),
          autorizacao: String(value?.autorizacao),
          price: String(value?.price),
          utm_campaign: value?.utmCampaign || '',
          adjust_event_token:
            value.event === Events.ANTECIPACAO_FGTS_SUCESSO
              ? '8oj5b4'
              : value?.adjust_event_token || '',
        },
      })
    }
  },
}

export enum Actions {
  CLIQUE = 'clique',
  CARREGAR_PAGINA = 'Tela carregada',
  DADOS_CARREGADO = 'Dados carregado',
}

export enum Events {
  ANTECIPACAO_FGTS = 'ANTECIPACAO_FGTS',
  FAQ_DUVIDAS = 'FAQ_DUVIDAS',
  HEADER = 'HEADER',
  ANTECIPACAO_FGTS_SUCESSO = 'ANTECIPACAO_FGTS_SUCESSO',
  TOGGLE_VIDA_FGTS = 'TOGGLE_VIDA_FGTS',
  AUTOMATICO_ACESSO = 'FGTS_AUTO_ACESSO',
  LIMITES = 'FGTS_MAIS_LIMITE_LP',
}
